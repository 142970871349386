import React from 'react';
import Header from './components/Header/Header';
import ContactPage from './components/ContactPage/ContactPage';
import Footer from './components/Footer/Footer';
import './index.css'; // Assurez-vous d'importer le fichier CSS global

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <ContactPage />
      </main>
      <Footer />
    </div>
  );
};

export default App;
