import React from 'react';
import headerImage from '../../assets/images/header-image.jpg'; // Importation de l'image
import './Header.scss';

const Header = () => {
  return (
    <header className="header">
      <img src={headerImage} alt="Header" className="header-image" />
    </header>
  );
};

export default Header;
