import React from 'react';
import './ContactPage.scss';

const Contact = () => {
  return (
    <section id="contact" className="section">
      <h2>Site en Maintenance</h2>
      <p>Notre site est actuellement en maintenance. Merci de votre compréhension.</p>
      
      <div className="contact-banner">
        <h3>Contactez-nous :</h3>
        <p>Portable : 06 60 60 87 60</p>
        <p>Fixe : 04 74 11 70 79</p>
        <p>
          Adresse : <a href="https://www.google.com/maps/search/?api=1&query=10+chemin+de+Montlouvier%2C+38150+Anjou" target="_blank" rel="noopener noreferrer">
            10 chemin de Montlouvier, 38150 Anjou
          </a>
        </p>
      </div>
      
      <a className='facebook' href="https://www.facebook.com/graux.olivier" target="_blank" rel="noopener noreferrer">
        Visitez notre page Facebook
      </a>
      
      <p>Merci de votre visite</p>
    </section>
  );
};

export default Contact;
